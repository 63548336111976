/* eslint-disable no-unused-vars */
import { Input } from "antd"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "../../../theme"
import { CharacterCounter } from "../../../utils"

export interface TextFieldProps {
  placeholder?: string
  type?: string
  onChange?: any
  className?: any
  onFocus?: any
  value?: any
  disabled?: boolean
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  rows?: number
  fullWidth?: boolean
  width?: string
  height?: string
  showCounting?: boolean
  maxLength?: number
  bordercolor?: string
  bgcolor?: string
  color?: string
  error?: any
  name?: string
  borderradius?: string
  placeholdercolor?: string
  label?: string
  addonBefore?: string
  disableboxshadow?: boolean
  required?: boolean
  indent?: string
  ref?: any
  autoComplete?: string
  enterKeyHint?: any
  onKeyUp?: (val: any) => void
  onKeyDown?: (val: any) => void
  onPressEnter?: (e: any) => void
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  noPasswordIconText?: boolean
}

const InputStyled = styled(Input)`
  font-size: 16px;
  border-radius: ${({ borderradius }: TextFieldProps) => borderradius || "4px"};
  height: ${({ height }: TextFieldProps) =>
    height ? height : "50px"} !important;
  border: ${({ bordercolor }: TextFieldProps) =>
    `1px solid ${bordercolor || theme.borderColorBase}`};
  background: ${({ bgcolor }: TextFieldProps) =>
    bgcolor ? bgcolor : "#f8f5ef"};
  width: ${({ width, fullWidth }: TextFieldProps) =>
    fullWidth ? "100%" : width || "auto"};
  text-indent: ${({ indent }: TextFieldProps) => indent || "0px"};
  ::placeholder {
    color: ${({ placeholdercolor }) =>
      (placeholdercolor && placeholdercolor) || theme.placeholder};
    text-indent: ${({ indent }: TextFieldProps) => indent || "0px"};
    vertical-align: middle;
  }
  position: relative;
  box-shadow: ${({ disableboxshadow }) =>
    disableboxshadow ? "none" : "inset 0px 2px 5px rgba(0, 0, 0, 20%)"};

  @media (max-width: 786px) {
    font-size: 16px;
  }
`

const StyledInputPassword = styled(Input.Password)`
  border-radius: ${({ borderradius }: TextFieldProps) => borderradius || "4px"};
  height: ${({ height }: TextFieldProps) =>
    height ? height : "50px"} !important;
  border: ${({ bordercolor }: TextFieldProps) =>
    `1px solid ${bordercolor || theme.borderColorBase}`};
  background: ${({ bgcolor }: TextFieldProps) =>
    bgcolor ? bgcolor : "#f8f5ef"};
  width: ${({ width, fullWidth }: TextFieldProps) =>
    fullWidth ? "100%" : width || "auto"};
  text-indent: ${({ indent }: TextFieldProps) => indent || "0px"};
  ::placeholder {
    color: ${({ placeholdercolor }) =>
      (placeholdercolor && placeholdercolor) || theme.placeholder};
    text-indent: ${({ indent }: TextFieldProps) => indent || "6px"};
    vertical-align: middle;
  }
  position: relative;
  box-shadow: ${({ disableboxshadow }) =>
    disableboxshadow ? "none" : "inset 0px 2px 5px rgba(0, 0, 0, 20%)"};
  .ant-input-password-icon {
    width: 48px;
  }
`

const ErrorStyled = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-family: ${theme.font.BUS || theme.font.NS};
  font-weight: ${theme.text.weight.regular};
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
`

const CountingStyled = styled.div<{ isRed: boolean }>`
  font-size: 16px;
  color: ${({ isRed }) => isRed && theme.alert};
`

const ErrorCounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
`

const TextFieldWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;

  .dynamic-field {
    border-radius: 10px !important;
    min-height: 53px;
    padding: 11px 10px;
    background-color: ${theme.background.primary};
    box-shadow: none;
    font-size: 16px;

    @media (max-width: 786px) {
      font-size: 16px;
      padding: 15px 10px;
    }
  }

  width: ${({ width, fullWidth }: TextFieldProps) =>
    fullWidth ? "100%" : width || "auto"};

  .ant-input {
    &:focus,
    &:hover,
    &:active {
      border-color: #8b94a5;
    }
    &-prefix {
      color: ${(props) => props.theme.text.color.gray};
    }
    &-suffix {
      color: ${(props) => props.theme.text.color.gray};
    }
    font-size: ${(props) => props.theme.text.size.s};
    font-family: ${(props) => props.theme.font.BUS};
  }

  .show-counting {
    color: #7c7c7c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`

const Label = styled.div`
  ${theme.typography.label};
  margin-right: 10px;
  font-weight: 500;
`

const RequiredLabel = styled.div`
  ${theme.typography.PackDesc};
  color: #f5222d;
  min-width: 40px;
  height: 22px;
  display: flex;
  justify-content: center;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 2px;
  align-items: center;
`

const TextField: React.FC<TextFieldProps> = React.forwardRef((props, ref) => {
  const {
    showCounting,
    value,
    maxLength,
    type,
    rows,
    className,
    label,
    required,
    prefix,
    suffix,
    enterKeyHint,
    noPasswordIconText,
    ...rest
  } = props
  const { TextArea } = Input
  const countingUI = useCallback(() => {
    return (
      showCounting && (
        <CountingStyled
          isRed={value?.length >= maxLength}
          className="show-counting"
        >
          {`${
            value ? CharacterCounter(value) : 0
          }/${maxLength.toLocaleString()}`}
        </CountingStyled>
      )
    )
  }, [value, maxLength])
  const { t } = useTranslation()
  const textFieldUI = () => {
    switch (type) {
      case "password":
        return (
          <TextFieldWrapperStyled className={className} width={props.width}>
            {label && (
              <LabelContainer>
                <Label>{label}</Label>
                {required && <RequiredLabel>必須</RequiredLabel>}
              </LabelContainer>
            )}
            {noPasswordIconText ? (
              <StyledInputPassword
                {...rest}
                prefix={prefix}
                placeholder={props.placeholder}
                type="password"
                autoComplete="new-password"
              />
            ) : (
              <StyledInputPassword
                {...rest}
                prefix={prefix}
                placeholder={props.placeholder}
                iconRender={(visible) =>
                  visible ? (
                    <div className="password-text">{t("Hide")}</div>
                  ) : (
                    <div className="password-text">{t("Show")}</div>
                  )
                }
                type="password"
                autoComplete="new-password"
              />
            )}
            <ErrorCounterWrapper>
              {countingUI()}
              {props.error ? (
                <ErrorStyled className={"err-text"}>{props.error}</ErrorStyled>
              ) : (
                <div />
              )}
            </ErrorCounterWrapper>
          </TextFieldWrapperStyled>
        )
      case "textarea":
        return (
          <TextFieldWrapperStyled className={className} width={props.width}>
            {label && (
              <LabelContainer>
                <Label>{label}</Label>
                {required && <RequiredLabel>必須</RequiredLabel>}
              </LabelContainer>
            )}
            <InputStyled
              as={TextArea}
              value={value}
              autoSize={{ minRows: rows }}
              placeholder={props.placeholder}
              maxLength={maxLength}
              enterKeyHint={enterKeyHint}
              // onKeyPress={(e: any) => {
              //   console.log("e.which", e.which)
              //   e.which === 13 && e.preventDefault()
              // }}
              {...rest}
            />
            <ErrorCounterWrapper>
              {props.error ? (
                <ErrorStyled className={"err-text"}>{props.error}</ErrorStyled>
              ) : (
                <div />
              )}
              {countingUI()}
            </ErrorCounterWrapper>
          </TextFieldWrapperStyled>
        )
      case "number":
        return (
          <TextFieldWrapperStyled className={className} width={props.width}>
            {label && (
              <LabelContainer>
                <Label>{label}</Label>
                {required && <RequiredLabel>必須</RequiredLabel>}
              </LabelContainer>
            )}
            <InputStyled
              prefix={prefix}
              suffix={suffix}
              value={value}
              type="number"
              maxLength={maxLength}
              {...rest}
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault()
              }}
            />
            <ErrorCounterWrapper>
              {props.error ? (
                <ErrorStyled className={"err-text"}>{props.error}</ErrorStyled>
              ) : (
                <div />
              )}
              {countingUI()}
            </ErrorCounterWrapper>
          </TextFieldWrapperStyled>
        )
      case "dynamic-textarea":
        return (
          <TextFieldWrapperStyled className={className} width={props.width}>
            {label && (
              <LabelContainer>
                <Label>{label}</Label>
                {required && <RequiredLabel>必須</RequiredLabel>}
              </LabelContainer>
            )}
            <TextArea
              value={value}
              autoSize
              {...rest}
              size="large"
              maxLength={maxLength}
              className="dynamic-field"
            />
            <ErrorCounterWrapper>
              {props.error ? (
                <ErrorStyled className={"err-text"}>{props.error}</ErrorStyled>
              ) : (
                <div />
              )}
              {countingUI()}
            </ErrorCounterWrapper>
          </TextFieldWrapperStyled>
        )

      default:
        return (
          <TextFieldWrapperStyled className={className} width={props.width}>
            {label && (
              <LabelContainer>
                <Label>{label}</Label>
                {required && <RequiredLabel>必須</RequiredLabel>}
              </LabelContainer>
            )}
            <InputStyled
              ref={ref}
              prefix={prefix}
              suffix={suffix}
              value={value}
              maxLength={maxLength}
              enterKeyHint={enterKeyHint}
              onKeyPress={(e: any) => {
                e.which === 13 && e.preventDefault()
              }}
              role="presentation"
              autoComplete="off"
              {...rest}
            />
            <ErrorCounterWrapper>
              {props.error ? (
                <ErrorStyled className={"err-text"}>{props.error}</ErrorStyled>
              ) : (
                <div />
              )}
              {countingUI()}
            </ErrorCounterWrapper>
          </TextFieldWrapperStyled>
        )
    }
  }
  return textFieldUI()
})

export { TextField }
