import { ConfigProvider, Select } from "antd"
import jaJP from "antd/lib/locale/ja_JP"
import i18next, { t } from "i18next"
import React from "react"
import styled from "styled-components"
import { theme } from "../../../theme"

export interface ISelect {
  children?: React.ReactNode
  isActive?: boolean
  allowClear?: boolean
  bordered?: boolean
  onChange?: (values, option?: any) => void
  isLoading?: boolean
  tag?: string
  width?: number
  value?: any
  className?: string
  clear?: boolean
  defaultValue?: string | string[] | number | number[]
  options?: any[]
  type?: "validate" | "consultation" | ""
  dark?: boolean
  placeholder?: string
  error?: any
  name?: any
  bgColor?: string
  mode?: "multiple" | "tag"
  disabled?: boolean
  showSearch?: boolean
  filterOption?: any
  dropdownMatchSelectWidth?: boolean
}

const SelectWithStyle = styled(({ dark, bgColor, error, ...props }) => {
  return (
    <Select
      getPopupContainer={(trigger) => trigger.parentNode}
      {...dark}
      {...props}
      {...error}
      bgcolor={bgColor}
    />
  )
})`
  & .ant-select-selector {
    border-color: ${({ error }: ISelect) => {
      return error ? `${theme.alert} !important` : `#D2D2D2 !important`
    }};

    width: ${({ width }: ISelect) => {
      return width ? `${width} !important` : "auto"
    }};
  }
`

const StyledSelect = styled(Select)`
  width: 100%;
  min-width: ${({ width }: ISelect) => (width ? `${width}px` : "273px")};
  border: 1px solid ${theme.darkGrey};
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  .ant-select-selection-item {
    font-size: 16px;
  }
  @media (max-width: 786px) {
    .ant-select-selection-item {
      font-size: 16px;
    }
  }
`

const Error = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-family: ${theme.font.BUS || theme.font.NS};
  font-weight: ${theme.text.weight.regular};
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
`
const ErrorCounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
`
const SelectWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`
const SelectComponent = (props: ISelect) => {
  const { Option } = Select
  const {
    children,
    isActive = true,
    allowClear = false,
    bordered = false,
    isLoading,
    defaultValue,
    tag,
    width,
    placeholder,
    className,
    options,
    clear,
    type,
    onChange,
    name,
    dark,
    value,
    mode,
    disabled,
    showSearch,
    filterOption,
    dropdownMatchSelectWidth = false,
    ...rest
  } = props

  return (
    <ConfigProvider
      locale={i18next.language === "ja" && jaJP}
      renderEmpty={() => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <svg
            className={"ant-empty-img-simple"}
            width={"64"}
            height={"41"}
            viewBox={"0 0 64 41"}
            xmlns={"http://www.w3.org/2000/svg"}
          >
            <g transform={"translate(0 1)"} fill={"none"} fillRule={"evenodd"}>
              <ellipse
                className={"ant-empty-img-simple-ellipse"}
                cx={"32"}
                cy={"33"}
                rx={"32"}
                ry={"7"}
              ></ellipse>
              <g className={"ant-empty-img-simple-g"} fillRule={"nonzero"}>
                <path
                  d={
                    "M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                  }
                ></path>
                <path
                  d={
                    "M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  }
                  className={"ant-empty-img-simple-path"}
                ></path>
              </g>
            </g>
          </svg>
          <p>{t("No data")}</p>{" "}
        </div>
      )}
    >
      {type === "validate" ? (
        <SelectWithStyle
          allowClear={clear}
          onChange={onChange}
          name={name}
          dark={dark}
          value={value}
          mode={mode}
          className={className}
          placeholder={props.placeholder}
          showArrow
          {...rest}
        >
          {options &&
            options.map((option: any, index: number) =>
              typeof option == "object" ? (
                <Option
                  key={index}
                  value={option.value}
                  disabled={option.disabled === true}
                >
                  {option.label}
                </Option>
              ) : (
                <Option key={index} value={option}>
                  {option}
                </Option>
              )
            )}
        </SelectWithStyle>
      ) : type === "consultation" ? (
        <SelectWrapperStyled>
          <StyledSelect
            isActive={isActive}
            showSearch={showSearch}
            allowClear={allowClear}
            bordered={bordered}
            onChange={onChange}
            isLoading={isLoading}
            value={value}
            defaultValue={defaultValue}
            tag={tag}
            width={width}
            placeholder={placeholder}
            className={className}
            filterOption={filterOption}
            disabled={disabled || false}
          >
            {options &&
              options.map((option: any, index: number) =>
                typeof option == "object" ? (
                  <Option
                    key={index}
                    value={option.value}
                    disabled={option.disabled === true}
                    phone={option.phone || null}
                  >
                    {option.label}
                  </Option>
                ) : (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              )}
          </StyledSelect>
          {props.error && (
            <ErrorCounterWrapper>
              <Error>{props.error}</Error>
            </ErrorCounterWrapper>
          )}
        </SelectWrapperStyled>
      ) : (
        <SelectWrapperStyled>
          <StyledSelect
            isActive={isActive}
            showSearch={showSearch}
            allowClear={allowClear}
            bordered={bordered}
            onChange={onChange}
            isLoading={isLoading}
            value={value}
            defaultValue={defaultValue}
            tag={tag}
            width={width}
            placeholder={placeholder}
            className={className}
            disabled={disabled || false}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          >
            {children}
          </StyledSelect>
          {props.error && (
            <ErrorCounterWrapper>
              <Error>{props.error}</Error>
            </ErrorCounterWrapper>
          )}
        </SelectWrapperStyled>
      )}
    </ConfigProvider>
  )
}

export { SelectComponent }
